const radioOptions = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const campaignOptions = [
  { label: "Cannes", value: "Cannes" },
  { label: "Summer Sale", value: "Summer_Sale" },
];

const audienceOptions = [{ label: "Global", value: "Global" }];

const inputs = [
  {
    label: "Campaign",
    heading: "Campaign",
    type: "select",
    options: campaignOptions,
  },
  { label: "products", heading: "products", type: "select", options: [] },
  {
    label: "Performance Objective",
    heading: "Performance Objective",
    type: "radio",
    options: radioOptions,
  },
  {
    label: "Target Audience",
    heading: "Target Audience",
    type: "select",
    options: audienceOptions,
  },
  { label: "channels", heading: "Channel", type: "select", options: [] },
  { label: "components", heading: "Component", type: "select", options: [] },
  { label: "Format", heading: "Format", type: "select", options: [] },
];

export { radioOptions, campaignOptions, inputs };
