import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Avatar, styled, Link, Tooltip } from "@mui/material";
import Layout from "../../components/Layout";
import AvatarGia from "../../assets/avatar_gia.png";
import { useAppContext } from "../../context/AppContext";
import { useFormContext } from "../../context/FormContext";
import AvatarIcon from "../../icons/Avatar";
import LikeIcon from "../../icons/LikeIcon";
import UnlikeIcon from "../../icons/UnlikeIcon";
import ShareIcon from "../../icons/ShareIcon";
import CopyIcon from "../../icons/CopyIcon";
import BrandSelectorSwitch from "../../components/BrandSelectorLink";

import { useOktaAuth } from "@okta/okta-react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import GradientButton from "./../GIAForm/GradientButton";
import { useHistoryContext } from "../../context/HistoryContext";
import jsPDF from "jspdf";
import { v4 as uuidv4 } from "uuid";

const { WebPubSubClient } = require("@azure/web-pubsub-client");

const CardComponent = styled("form")(({ theme, ...rest }) => {
  const { mode } = theme.palette;
  const { backgroundGray } = theme?.palette[mode];
  return {
    width: "900px",
    backgroundColor: backgroundGray,
    padding: "36px 35px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "18px",
  };
});

const TypingLoader = styled("form")(({ theme, ...rest }) => {
  const { mode } = theme.palette;
  const { backgroundGray } = theme?.palette[mode];
  return {
    width: "200px",
    backgroundColor: backgroundGray,
    padding: "26px 25px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "18px",
  };
});

const StreamingLoader = (props) => {
  return (
    <TypingLoader>
      <div className="flex items-center justify-center space-x-2 animate-pulse">
        <LoadingDots className="animate-bounce 1.4s infinite ease-in-out both"></LoadingDots>
        <LoadingDots className="animate-bounce 0.9s infinite ease-in-out both"></LoadingDots>
        <LoadingDots className="animate-bounce 0.5s infinite ease-in-out both"></LoadingDots>
      </div>
    </TypingLoader>
  );
};

const LoadingDots = styled("div")(({ theme, ...rest }) => {
  const { mode } = theme.palette;
  const { black } = theme?.palette[mode];
  return {
    display: "flex",
    gap: "4px",
    justifyContent: "center",
    alignItems: "center",
    width: "15px",
    height: "15px",
    backgroundColor: black,
    borderRadius: "50%",
    ...rest,
  };
});

const HeadingComponent = styled("form")(({ theme, ...rest }) => {
  const { mode } = theme.palette;
  const {} = theme?.palette[mode]; // eslint-disable-line
  return {
    width: "900px",
  };
});

const text1 = "Reading assignment​";
const text2 = "Reviewing the rule book";
const text3 = "Scouring style guides​";
const text4 = "Adjusting for Brand Objective​";
const text5 = "Finishing up​";

let loadingTextList = [
  `${text1}.`,
  `${text1}..`,
  `${text1}...`,
  `${text2}.`,
  `${text2}..`,
  `${text2}...`,
  `${text3}.`,
  `${text3}..`,
  `${text3}...`,
  `${text4}.`,
  `${text4}..`,
  `${text4}...`,
  `${text5}.`,
  `${text5}..`,
  `${text5}...`,
];

const FormCard = (props) => {
  const { themeMode, theme } = useAppContext();
  const history = useHistory();
  const {
    globalAppConfig,
    pubsubClientAccessUrl,
    selectedBrand,
    appConfig,
    setAppErrorMessage,
    getClientAccessUrl,
    setIsConfigLoaded,
  } = useAppContext();
  const {
    formState,
    quickStartQuery,
    isFormValid,
    generatedCopy,
    setGeneratedCopy,
    streamingResponse,
    setStreamingResponse,
    setStreamingStepCount,
    streamingStepCount,
    giaFormInputQuery,
    copyId,
    setCopyId,
  } = useFormContext();
  const { authState } = useOktaAuth();
  const { historyQuery, setHistoryQuery } = useHistoryContext();

  const [copyTooltip, setCopyTooltip] = useState("Copy");
  const { updateFeedback } = useHistoryContext();
  const [likeState, setLikeState] = useState(null);
  const [brandValue, setBrandValue] = useState("");
  const [brandIndex, setBrandIndex] = useState("");
  const [loading, setLoading] = useState(true);
  const [warningMessage, setWarningMessage] = useState("");
  const [showRegenerateButton, setShowRegenerateButton] = useState(false);
  const intervalRef = React.useRef(null);
  const quickGenAbortRef = React.useRef(null);
  const submitFormAbortRef = React.useRef(null);

  const stepCountRef = React.useRef(0);
  stepCountRef.current = streamingStepCount;
  const { mid_gray, fill } = theme?.palette[themeMode];

  const [connectionId, setConnectionId] = useState("");

  useEffect(() => {
    setIsConfigLoaded(
      appConfig.length > 0 && Object.keys(globalAppConfig).length > 0
    );
    // Once Config Loaded get the pubsub client access url
    if (
      appConfig.length > 0 &&
      Object.keys(globalAppConfig).length > 0 &&
      !pubsubClientAccessUrl
    )
      getClientAccessUrl();
  }, [appConfig, globalAppConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  //Streaming--------------------------------------------------------
  const setWs = async (client) => {
    await client.start();
    client.on("connected", (e) => {
      console.log(`CONNECTION ${e.connectionId} IS CONNECTED.`);
      setConnectionId(e.connectionId);
    });
    client.on("server-message", (e) => {
      if (e.message.data === "New Chain" && stepCountRef.current === 0) {
        setLoading(false);
        setStreamingStepCount(stepCountRef.current + 1);
      } else if (e.message.data === "New Chain" && stepCountRef.current === 1) {
        setStreamingStepCount(stepCountRef.current + 1);
      } else if (e.message.data === "New Chain" && stepCountRef.current === 2) {
        setStreamingStepCount(stepCountRef.current + 1);
      } else if (e.message.data !== "New Chain" && stepCountRef.current === 3) {
        setStreamingResponse((res) => {
          if (e.message.data.length > 0) {
            clearInterval(intervalRef.current);
            if (historyQuery.length === 0) {
              setLoading(false);
            }
          }
          return e.message.data.length > 0
            ? (loadingTextList.indexOf(res) >= 0 ? "" : res) + e.message.data
            : res;
        });
      }
    });
    client.on("disconnected", (e) => {
      console.log(`CONNECTION DISCONNECTED: ${e.message}`);
    });
  };
  useEffect(() => {
    let client = null;
    if (pubsubClientAccessUrl && connectionId === "") {
      try {
        if (!globalAppConfig?.dynamicPromptsUrl) return;
        client = new WebPubSubClient({
          getClientAccessUrl: pubsubClientAccessUrl,
        });
        if (connectionId === "") {
          setWs(client);
        }
      } catch (error) {
        console.log("WS error", error);
      }
    }

    return async () => {
      setStreamingStepCount(0);
      setStreamingResponse("");
      setGeneratedCopy("");
      await client?.stop();
      clearInterval(intervalRef.current);
    };
  }, [globalAppConfig, pubsubClientAccessUrl]); // eslint-disable-line react-hooks/exhaustive-deps
  //------------------------------------------------------------------------------

  const initiateSteps = () => {
    let counter = 0;
    let localLoadingTextList = loadingTextList;

    intervalRef.current = setInterval(() => {
      setStreamingResponse(
        localLoadingTextList[counter] || "Reading assignment. "
      );
      setLoading(false);
      if (counter < localLoadingTextList.length - 1) counter++;
      else {
        console.log("in else ");
        counter = 0;
        localLoadingTextList = [`${text5}.`, `${text5}..`, `${text5}...`];
      }
    }, 1000);
  };
  useEffect(() => {
    const abortController = new AbortController();
    quickGenAbortRef.current = abortController;
    if (connectionId && !isFormValid) giaQuickCopyGenerate(abortController);

    return () => {
      abortController.abort();
    };
  }, [quickStartQuery, connectionId]); // eslint-disable-line

  useEffect(() => {
    const abortController = new AbortController();
    submitFormAbortRef.current = abortController;
    if (connectionId && isFormValid) submitForm(abortController);

    return () => {
      abortController.abort();
    };
  }, [giaFormInputQuery, connectionId]); // eslint-disable-line

  useEffect(() => {
    if (generatedCopy) {
      localStorage.setItem("generatedCopy", generatedCopy);
    }
  }, [generatedCopy]);

  useEffect(() => {
    const savedCopy = localStorage.getItem("generatedCopy");
    if (savedCopy) {
      setGeneratedCopy(savedCopy);
    }
  }, []);

  const giaQuickCopyGenerate = (abortController) => {
    if (!quickStartQuery) return;
    setGeneratedCopy("");
    clearInterval(intervalRef.current);
    setStreamingStepCount(3);
    const { email, sub: userId } = authState?.idToken?.claims || {};
    const oktaToken = JSON.parse(sessionStorage.getItem("okta-token-storage"))
      ?.idToken?.idToken;
    const requestId = uuidv4();
    axios
      .post(
        globalAppConfig?.giaQuickGenUrl,
        {
          query: quickStartQuery,
          brand_name: brandValue,
          streaming: true,
          connectionId: connectionId,
          collection: brandIndex,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${oktaToken}`,
            "X-User-Email": email,
            "X-User-Aud": userId,
            "ocp-apim-subscription-key": globalAppConfig?.apiKey,
            "x-req-id": requestId,
          },
          signal: abortController?.signal,
        }
      )
      .then((response) => {
        setLoading(false);
        setStreamingStepCount(0);
        setStreamingResponse("");
        setGeneratedCopy(response?.data?.copy); // response.data.id
        setCopyId(response?.data?.id);
      })
      .catch((error) => {
        setLoading(false);
        if (error?.code !== "ERR_CANCELED") {
          setAppErrorMessage(error?.response?.data || "Something went wrong");
        }
      });
  };

  useEffect(() => {
    setBrandValue(selectedBrand?.id);
    setBrandIndex(selectedBrand?.collection);
  }, [selectedBrand]);

  function submitForm(abortController) {
    if (!giaFormInputQuery) return;
    clearInterval(intervalRef.current);
    setGeneratedCopy("");
    initiateSteps();
    const { email, sub: userId } = authState?.idToken?.claims || {};
    const oktaToken = JSON.parse(sessionStorage.getItem("okta-token-storage"))
      ?.idToken?.idToken;
    const requestId = uuidv4();
    axios
      .post(
        globalAppConfig?.dynamicPromptsUrl,
        {
          query: giaFormInputQuery,
          brand_name: brandValue,
          streaming: true,
          connectionId: connectionId,
          collection: brandIndex,
          channel: formState.channels,
          component: formState.components,
          layout: formState.Format,
          product: formState.products,
          contextual_knowledge: formState.products,
          creative_score: formState["Performance Objective"],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${oktaToken}`,
            "X-User-Email": email,
            "X-User-Aud": userId,
            "ocp-apim-subscription-key": globalAppConfig?.apiKey,
            "x-req-id": requestId,
          },
          signal: abortController?.signal,
        }
      )
      .then((response) => {
        setLoading(false);
        setStreamingStepCount(0);
        setStreamingResponse("");
        setGeneratedCopy(response.data?.brand_guidelines_and_compliance);
        setCopyId(response?.data?.copy_id);
        if (response.data?.ungrounded) {
          setWarningMessage(
            "The generated copy does not align with GIA's knowledge and guidelines. Please feel free to discard the current copy and regenerate it if it doesn't meet your requirements."
          );
        } else {
          setWarningMessage("");
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.code !== "ERR_CANCELED") {
          setAppErrorMessage(error?.response?.data || "Something went wrong");
        }
      });
  }

  const handleCopy = () => {
    const textToCopy = generatedCopy;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopyTooltip("Copied!");
        setTimeout(() => setCopyTooltip("Copy"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleFeedback = (feedback) => {
    setLikeState(feedback);
    updateFeedback({
      brand: brandValue,
      feed_back_action: feedback,
      copy_id: copyId,
    });
  };

  const handleRegenerate = async () => {
    setHistoryQuery("");
    clearInterval(intervalRef.current);
    setLoading(true);
    setShowRegenerateButton(false);
    setLikeState(null);

    await getClientAccessUrl();

    if (isFormValid) {
      submitForm(submitFormAbortRef.current);
    } else {
      giaQuickCopyGenerate(quickGenAbortRef.current);
    }
  };

  useEffect(() => {
    if (!loading && generatedCopy) {
      setShowRegenerateButton(true);
    }
  }, [loading, generatedCopy]);

  const handleShare = () => {
    try {
      const pdf = new jsPDF("p", "pt", "a4");
      const timestamp = new Date().getTime();
      const markdownContent = document.querySelector("#pdf-content").innerHTML;
      const htmlContent = `
        <div><b>${quickStartQuery || giaFormInputQuery}</b></div>
        ${markdownContent}
      `;

      pdf.html(htmlContent, {
        callback: function (pdf) {
          // Adjust the size of the PDF
          const pageCount = pdf.internal.getNumberOfPages();
          for (let i = 0; i < pageCount; i++) {
            pdf.setPage(i + 1);
            pdf.internal.pageSize.height = pdf.internal.pageSize.getHeight();
          }
          pdf.save(`${brandValue}_${timestamp}.pdf`);
        },
        x: 20,
        y: 20,
        width: 555,
        windowWidth: 800,
      });
    } catch (error) {
      console.error("Failed to generate PDF", error);
    }
  };

  const formKeys = Object.keys(formState);
  return (
    <Layout>
      <div>
        <div className="flex mt-10 gap-4 lex flex-col">
          <div className="ml-[-58px] flex">
            <AvatarIcon fill={fill} />
            <div className="flex flex-col">
              <HeadingComponent>
                <p className="ml-2">
                  {isFormValid && giaFormInputQuery
                    ? giaFormInputQuery
                    : quickStartQuery}
                </p>
              </HeadingComponent>
              <div className="pl-2.5 leading-[0px]">
                {formKeys.map((key, ki) => {
                  const keyName = key.toLowerCase();
                  if (
                    keyName.includes("audience") ||
                    keyName.includes("campaign") ||
                    keyName.includes("performance") ||
                    formState[key].length === 0
                  )
                    return null;
                  return (
                    <span
                      style={{ color: mid_gray }}
                      className="pr-1.5 text-xs"
                      key={ki}
                    >
                      {formState[key]?.replaceAll("_", " ")}{" "}
                      {ki < formKeys.length ? "." : "-"}{" "}
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div>
          {warningMessage && (
            <div
              className="w-[900px] mt-[10px]"
              style={{
                color: "#AAA24E",
              }}
            >
              Warning: {warningMessage}
            </div>
          )}
        </div>
        <div className="flex mt-10">
          <Avatar className="absolute right-14" alt="GIA" src={AvatarGia} />
          <div className="ml-[-34px]">
            {loading ? (
              <StreamingLoader />
            ) : (
              <CardComponent>
                <div id="pdf-content">
                  <ReactMarkdown
                    className="pb-2"
                    remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                  >
                    {generatedCopy.length > 0
                      ? generatedCopy
                      : streamingResponse}
                  </ReactMarkdown>
                </div>

                {generatedCopy.length > 0 && (
                  <div className="flex gap-4 mt-6 justify-end items-center">
                    <Tooltip title="Like" arrow>
                      <div
                        onClick={() => handleFeedback("like")}
                        className="cursor-pointer"
                      >
                        <LikeIcon
                          fill={likeState === "like" ? "#1877F2" : "#AAAAAA"}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="dislike" arrow>
                      <div
                        onClick={() => handleFeedback("dislike")}
                        className="cursor-pointer"
                      >
                        <UnlikeIcon
                          fill={likeState === "dislike" ? "#1877F2" : "#AAAAAA"}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="download" arrow>
                      <div onClick={handleShare} className="cursor-pointer">
                        <ShareIcon />
                      </div>
                    </Tooltip>
                    <Tooltip title={copyTooltip} arrow>
                      <div onClick={handleCopy} className="cursor-pointer">
                        <CopyIcon />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </CardComponent>
            )}
          </div>
        </div>
        {generatedCopy.length > 0 ? (
          <div className="flex mt-10 mb-10 gap-4">
            {showRegenerateButton && (
              <>
                <GradientButton
                  label="GIA RE-GENERATE"
                  onClick={handleRegenerate}
                >
                  {" "}
                  GIA RE-GENERATE{" "}
                </GradientButton>

                <Link
                  component="button"
                  underline="none"
                  onClick={() => history.push("/form")}
                >
                  {" "}
                  Start New{" "}
                </Link>
              </>
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      {selectedBrand && (
        <BrandSelectorSwitch
          src={selectedBrand.src}
          label={selectedBrand.label}
          title="Change client"
        />
      )}
    </Layout>
  );
};

export default FormCard;
